import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '@solid-ui-layout/Layout';
import Seo from '@solid-ui-components/Seo';
import Divider from '@solid-ui-components/Divider';
import Header from '@solid-ui-blocks/Header/Block01';
import Message from '@solid-ui-blocks/FeaturesWithPhoto/Block01';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import styles from './_styles';
import { Container, Box, Flex } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';
import errorImage from './404error.png';

const NotFoundPage = props => {
    const { allBlockContent } = props.data;
    const content = normalizeBlockContentNodes(allBlockContent?.nodes);

    useEffect(() => {
        if (window && window.location.pathname === '/rimor') {
            window.location.href = 'https://rimor.juit.io';
        }
    }, []);

    return (
        <Layout {...props}>
            <Seo title="Página não encontrada" />
            <Header content={content['header-light']} menuJustify="space-around" />
            <Container variant="full" sx={styles.heroContainer}>
                <Divider space="5" />
            </Container>
            <Divider space="5" />
            <Divider space="5" />
            <Container variant="wide">
                <Flex sx={{ flexDirection: ['column', 'row'], alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ marginBottom: ['20px', '0'] }}>
                        <StaticImage src={'./404error.png'} alt="404" placeholder="404" layout="constrained" />
                    </Box>
                    <Message content={content['404']} reverse />
                </Flex>
            </Container>
            <Divider space="5" />
            <Divider space="5" />
            <Divider space="5" />
        </Layout>
    );
};

export const query = graphql`
    query misc404BlockContent {
        allBlockContent(filter: { page: { in: ["innerpage/404", "shared"] } }) {
            nodes {
                ...BlockContent
            }
        }
    }
`;

export default NotFoundPage;
